import axios from "axios"

/**请求请求到node层再进行转发 */
export const post = (params:any,req?:any)=>{
  try{
    const transferUrl = params?.url;
    const data = params?.data;
    const protocol = req ? (req?.headers['x-forwarded-proto'] || req?.connection?.encrypted ? 'https:' : 'http:') : window.location.protocol;
    const host = req?.headers?.host || location?.host;
    let url = `${protocol}//${host}/api/transfer?url=${transferUrl}`

    return new Promise((resolve,reject)=>{
      axios({
        method:'post',
        url,
        data:{
          transferUrl,
          transferData:data,
        },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          // 'Connection': 'keep-alive',
          // 'Keep-Alive': 'timeout=60',
        },
      }).then(res=>{
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    }) 
  }catch(err){
    console.log("🚀 ~ FetchApi ~ post ~ err:", err)
  }
}

export default { post }